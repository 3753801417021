.header-top{
  padding:10px;
  .make-row();

  .main-logo-wrapper{
    .make-lg-column(3);
    .make-md-column(3);
    .make-sm-column(3);
    .make-xs-column(3);
  }

  .header-phone{
    .make-lg-column(3);
    .make-md-column(3);
    .make-sm-column(6);
    .make-xs-column(6);
    text-align:right;
    font-size: 1.7em;
    font-family:"SegoeUILight", Arial;

  }

  .header-ads-slot{
    .make-lg-column(6);
    .make-md-column(6);
    .make-sm-column(3);
    .make-xs-column(3);
  }
}

.logo-wrapper{
  .pull-left();
  margin-right:10px;
  height:40px;
  background:url("/images/logo.png") no-repeat no-repeat;
  a {
    display:inline-block;
    width:80px;
    height:40px;
  }
}

.main-search-wrapper{
  .pull-right();
  text-align:right;
  margin-top:18px;
  .main-search{
    padding:1px 0 3px 25px;
    width:150px;
    border:0px;
    border-bottom:solid 1px #fff;
    color:#fff;
    outline:none;
    background:#000 url("/images/search_xs.png") 0% 50% no-repeat no-repeat;
  }
}

.main-menu{
  background:#000000;
  padding:10px 0;
  @media (min-width: @screen-sm-min) {
    width: @container-sm;
  }
  @media (min-width: @screen-md-min) {
    width: @container-md;
  }
  @media (min-width: @screen-lg-min) {
    width: @container-lg;
  }
  margin:auto;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.3);
}

/*#2D84DC
#1C4FB8
#0F3CA3
#3172D1*/

//#1A9EFF

.main-menu-items{
  list-style:none;
  text-transform: uppercase;
  font-family: "SegoeUIRegular", Arial;
  font-weight:bold;
  margin:0;
  padding:10px 0 0 0;
  font-size:16px;

  .main-menu-item{
    display:inline-block;
    

    a{
      color:#fff;
      display:block;
      padding:10px;
      padding-bottom:0;
      text-decoration:none;
      &:hover{
        color:#ed1017;
      }
    }
  }
}
