footer{
  color:#000;
  
  position:absolute;
  margin:auto;
  bottom:0;
  width: 100%;
  .footer-inner{
    .make-row();
  }
  
  .container{
    background:#eee;
    padding-bottom:40px;
  }

  a{
    color:#000;
    text-decoration:none;
    &:hover{
      text-decoration:underline;
    }
  }
}



.footer-section{
  .section-header{
    padding:5px 0;
   
    box-shadow: 0 0.5px 0 0 rgba(200,200,200,0.3);
    font-size:18px;

  }

  &.common-footer-section{
    .make-lg-column(2);
  }

  &.products-section{
    .make-lg-column(7);
  }

  &.contacts-section{
    .make-lg-column(3);
  }



  .footer-nav{
    list-style:none;
    margin-left:5px;
    margin-right:10px;
    padding-left:0;
    display:inline-block;
    vertical-align:top;

  }

  .footer-product-categories{
    .category{
      display:inline-block;
      vertical-align:top;
      width:160px;
      margin-left:-4px;
      h5{
        font-size:0.9em;
      }
      .footer-nav-item{
        font-size:0.8em;
      }
    }
  }

  .footer-contacts-wrapper{
    display:table;
    .footer-contact{
      display:table-row;
      .contact-value, .contact-label{
        display:table-cell;
      }
      .phone, .email{
        padding-left:20px;
      }
    }
  }
}