@main-banner-text-color: #eee;

.main-header{
  color:@main-banner-text-color;
}
.main-banner-wrapper{
  .container-fluid();
 // background:#000;
  .main-banner-content{
    .container();

    .main-form{
      .make-lg-column(4);
      .make-md-column(4);
      .make-sm-column(4);
      .make-xs-column(12);

      .main-form-header{
        margin:10px 0;
        font-size:1.5em;
       // color:@main-banner-text-color;
      }

      input{
        .form-control();
      }

      .form-group.has-error input{
        background: @error-input-bg;
      }

      label{
        //color:@main-banner-text-color;
      }

      .main-form-button{
        padding:10px 20px;
        margin:20px 0;
        font-size:1.4em;
        font-family:Arial;
        font-weight:bold;
        //border-radius:5px;
        text-transform: uppercase;
        border:2px solid #ff7335;
        background:@danger-color;
        color:#fff;
      }
    }

    .main-banner-image-wrapper{
      .make-lg-column(8);
      .make-md-column(8);
      .make-sm-column(8);
      .hidden-xs();
      text-align:right;
      padding-top:8px;
      img{
        width:100%;
      }
    }
  }
}


.offer-header{
  font-family: "SegoeUILight", Arial;
  font-size:1.8em;
  padding:0 30px;
}


.main-product-offers-wrapper{
  .make-row();
  margin-top:40px;
  margin-bottom:40px;

  &.ip-offer{
    .product-offer{
      .make-xs-column(4);
      .make-sm-column(4);
      .make-md-column(2);
      .make-lg-column(2);
    }
  }

  &.hdcvi-offer{
    .product-offer{
      .make-xs-column(6);
      .make-sm-column(6);
      .make-md-column(3);
      .make-lg-column(3);
    }
  }

  .product-offer{
    height:170px;
    text-decoration:none;
    text-align:center;

    .offer-image{
      width:90%;
      max-width:145px;
    }

    .offer-text{
      display:block;
      text-align:center;
      color:#333;
      font-family: "SegoeUILight", Arial;
      font-weight:bold;
    }
  }
}

.main-apps-and-news{
  .make-row();
  margin-bottom:40px;
}


.main-applications-wrapper{
  .make-lg-column(4);
  .make-md-column(4);

  .application-wrapper{
    text-align:center;
    margin-bottom:10px;


    .application{
      text-align:left;
      display:inline-block;
      text-decoration:none;
    }

    .application-image{
      width:90%;
      max-width:280px;
    }

    .application-header{
      font-family: "SegoeUILight", Arial;
      font-size:1.5em;
      margin-bottom:10px;
      color:#333;
      text-decoration:none;
    }
  }
}

@media(max-width: @screen-md){
  .main-applications-wrapper{
    display:table;
    width:100%;
    .application-wrapper{
      display:table-cell;
      vertical-align:middle;
      text-align:middle;
    }
  }
}

.main-news-wrapper{
  //border:2px solid #ddd;

  .make-lg-column(8);
  .make-md-column(8);

  .news-header{
    font-family: "SegoeUILight", Arial;
    font-size:1.5em;
    margin-bottom:10px;
  }

}

.main-text{
  text-align:justify;
}