.latest-news{
  display:table;

  .news-list-wrapper{
    display:table-cell;
    vertical-align:middle;
  }

  .news-image-cell{
    display:table-cell;
    vertical-align:top;
    width:310px;
    border:none;
    .hidden-xs();

    .news-image-wrapper{
      position:relative;
      height:310px;

      .news-image{
        position:absolute;
        opacity:0;
        top:0;
        left:10px;

        img{
          max-width:310px;
          max-height:310px;
        }

        &.current{
          opacity:1;
        }
      }
    }
  }

  @media(max-width: @screen-lg){
    .news-image-cell{
      width:250px;
      .news-image-wrapper{
        width:250px;
        img{
          max-width:250px;
          max-height:250px;
        }
      }
    }
  }

  .news-list{
    list-style:none;
    margin-left:0;
    padding-left:0;

  }

  .news-item{
    padding:10px;
    background:#eee;
    border-bottom:solid 2px #fff;
    position:relative;
    z-index:10;
    cursor:pointer;

    .news-link{
      text-decoration:none;
      color:#666;
      height:40px;
      display:inline-block;
    }

    &:hover{
      background:#FDD772;
      &:after{
        display:block;
        position:absolute;
        top:0;
        left:100%;
        content:'';
        .arrow-right(#FDD772, 20px, 30px);
      }
    }
  }
}
